import React from "react";
import './../css/Profile.css';
import socials from "./../data/ProfileSocials.json";

export default function Profile() {
    return (
        <div className="profile">
            <div className="profile-column">
                <div className="profile-content">
                    <p>Hi! I'm a current senior studying electrical engneering at the University of Michigan. I'm also pursuing a minor in mathematics and computer science. I have interests in controls and signals.</p>
                    <div className="socials-container">
                        <ul className="socials-list">
                            {socials.map((social) => (
                                <li key={social.alt} className="profileListItem">
                                    <a href={social.href}>
                                        <img src={require(`../images/${social.image}`)} alt={social.alt}/>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
