
import React from "react";
import experiences from './../data/experienceData.json';
import './../css/Experience.css';

export default function ExperienceItem() {
    // const [expanded, setExpanded] = useState(new Array(experiences.length).fill(false));
    // const [prevIndex, setPrevIndex] = useState(0);

    // const toggleDescription = (index) => {
    //     if (prevIndex === index) {
    //         setExpanded(expanded.map((item, idx) => idx === index ? !item : item));
    //     } 
    //     else {
    //         setExpanded(expanded.map((item, idx) => idx === index));
    //         setPrevIndex(index);
    //     }
    // };
    

    return (
        <div className="experience-container">
          {experiences.map((exp, index) => (
            <div key={index} className="experience-item">
              <div className="experience-left">
                <img src={require(`../images/${exp.image}`)} alt={exp.company} />
                <h2>{exp.position}</h2>
                <h3>{exp.company}</h3>
                <p>{exp.years}</p>
              </div>
              <div className="experience-right">
                <ul>
                  {exp.description.map((desc, i) => (
                    <li key={i}>{desc}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      );
}
