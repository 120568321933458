import './App.css';
import React from 'react';
import Nava from './components/NavigationComponent'
import Profile from './components/ProfileComponent'
import ExperienceItem from './components/ExperienceComponent'
import ProjectItem from './components/ProjectComponent'
// import Contact from './components/EmailComponent'
import Space from './components/SpacerComponent'



function App() {
  return (
    <div className="App">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Matthew Inda</title>
      </head>
      <header>
        <div className="header">
          <Nava />
        </div>
      </header>
      <Space />
      <div id="home">
        <h1> Matthew Inda </h1>
        <Profile />
      </div>
      <Space />
      <div id="experience">
        <h1> Experience </h1>
        <ExperienceItem />
      </div>
      <Space />
      <div id="projects">
        <h1> Projects </h1>
        <ProjectItem />
      </div>
      <Space />
    </div>
  );
}


export default App;
