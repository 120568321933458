import React, {useState} from "react";
import projectsData from "./../data/projectsData.json";
import './../css/Projects.css';

export default function ProjectItem() {
    const [expanded, setExpanded] = useState(new Array(projectsData.length).fill(false));
    const [prevIndex, setPrevIndex] = useState(0);

    const toggleDescription = (index) => {
        if (prevIndex === index) {
            setExpanded(expanded.map((item, idx) => idx === index ? !item : item));
        } 
        else {
            setExpanded(expanded.map((item, idx) => idx === index));
            setPrevIndex(index);
        }
    };

    return (
        <div className="projects-container">
            {projectsData.map((project, index) => (
                <div key={project.id} className="project-item">
                    <div className="project-info">
                        <h3>{project.title}</h3>
                        <p>{project.caption}</p>
                        <p>{project.date}</p>
                        <div className={`description ${expanded[index] ? 'expanded' : 'collapsed'}`}>
                            <span>
                                {project.link !== "" && 
                                    <a href={project.link}>
                                        <span><img src={require("../images/githublogo2.png")} alt="GitHub Logo" /> <p><b>View on Github &gt;</b></p></span>
                                    </a>
                                }
                            </span>
                            <p>Utilized {project.technologies}</p>
                            <ul>
                                {project.description.map((point, idx) => (
                                    <li key={idx}>
                                        <p>{point}</p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="buttonDiv">
                        <button className="btn" onClick={() => toggleDescription(index)}>
                            {expanded[index] ? 'View Less' : 'View More'}
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
}
